import styled, { device, themeGet } from "@theme/utils";
// import WoorgLogoBG1 from "../../../data/images/brand-logo/WoorgLogoBG1.webp";
// import WoorgLogoBanner1 from "../../../data/images/brand-logo/WoorgLogoBanner1.png";
export const BrandSection = styled.section``;
export const BrandTitle = styled.h6`
    text-align: center;
    color: #fff;
    visibility: hidden;
    margin-bottom: 30px;
    span {
        font-weight: 900;
        line-height: 2.428;
        font-size: 35px;
        background: ${themeGet("colors.gradient")};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

export const BrandBoxText = styled.h3`
    text-align: center;
    color: #fff;
    margin-bottom: 36px;
    font-weight: 800;
    font-size: 35px;
    padding-bottom: 40px;
    ${device.xsmall} {
        font-family: "Ruda", sans-serif;
    }
    height: 70px;
    padding-right: 80px;
    padding-left: 80px;
    ${device.mobileview} {
        font-size: 20px;
        padding-right: 0px;
        padding-left: 0px;
    }
`;

export const BrandBox = styled.div`
    /* background-color: #02126a; */
    background-image: url(/svg/WoorgLogobg1.svg);
    height: 350px;
    padding: 50px 20px 70px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 35px;
    ${device.large} {
        margin-top: 85px;
    }
    ${device.mobileview} {
        height: 228px;
        padding-top: 52px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const BrandBox2 = styled.div`
    /* background-color: #02126a; */
    background-image: url(/svg/WoorgLogoBanner.png);
    height: 78%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 35px;
    ${device.large} {
        margin-top: -130px;
        height: 150%;
    }
    ${device.mobileview} {
        width: 271px;
        padding-bottom: 68px;
        margin: auto;
    }
`;
