import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import Button from "../../components/shared/button";
import {
    ServiceCard,
    ServiceIcon,
    RotedAround,
    ServiceContent,
    Title,
    DisText,
} from "./style";
const ServicesItem = ({
    lineImage,
    iconImage,
    title,
    disText,
    actionClass,
    outlineColor,
    slug,
}) => {
    const imageLine = getImage(lineImage);
    const imageIcon = getImage(iconImage);

    const adjustTitleLength = (title) => {
        let [titleFirstWord, titleSecondWord, titleThirdWord] =
            title.split(" ");
        return (
            <div>
                {titleFirstWord} {titleSecondWord}
                <br /> {titleThirdWord}
            </div>
        );
    };
    return (
        <ServiceCard>
            <GatsbyImage className="line" image={imageLine} alt={title} />
            <ServiceIcon>
                <RotedAround className={actionClass}>
                    <span></span>
                </RotedAround>
                <GatsbyImage
                    image={imageIcon}
                    alt={title}
                    style={{ width: "70px", height: "70px" }}
                />
            </ServiceIcon>
            <ServiceContent>
                <Title>
                    {title === "Mobile Apps Development"
                        ? adjustTitleLength(title)
                        : title}
                </Title>
                <DisText>{disText}</DisText>
                {slug === "mobile-apps-development" ||
                slug === "content-marketing" ||
                slug === "devops" ? (
                    <Button
                        size="medium"
                        shape="rounded5"
                        variant="outlined"
                        path={`/`}
                        color={outlineColor}
                        className="pt-40"
                    >
                        Details +
                    </Button>
                ) : (
                    <Button
                        size="medium"
                        shape="rounded5"
                        variant="outlined"
                        path={`/services/${slug}`}
                        color={outlineColor}
                    >
                        Details +
                    </Button>
                )}
            </ServiceContent>
        </ServiceCard>
    );
};
ServicesItem.propTypes = {
    slug: PropTypes.string,
    lineImage: PropTypes.object,
    iconImage: PropTypes.object,
    title: PropTypes.string,
    disText: PropTypes.string,
    actionClass: PropTypes.string,
    outlineColor: PropTypes.string,
};
export default ServicesItem;
